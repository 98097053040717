import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components"

const ContactPage = ({
  data: {
    prismicPage: {
      data: {
        ogimage
      }
    }
  }
}) => {
  return (
    <Layout 
      context={{
        title: 'AQ&F - Contact',
        description: `Contacter Arnaud Quevedo`,
        ogimage: ogimage.localFile.publicURL,
        slug: '/contact/'
      }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <a 
          className="contact" 
          href="mailto:arnaudquevedo.contact@gmail.com"
        >
          arnaudquevedo.contact@gmail.com
        </a>
      </div>
    </Layout>
  )
}

export default ContactPage
export const pageQuery = graphql`
  query contactPage {
    prismicPage(uid: { eq: "home" }) {
      uid
      data {
        ogimage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`